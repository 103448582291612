








































import Vue from 'vue';
import { Component, Inject, InjectReactive, Prop } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import { ValidationProvider } from 'vee-validate';
import { CurrencyInputOptions } from 'vue-currency-input';
import { formattingOptions } from './MultiStepForm.vue';
import NumberInput from '../../base/NumberInput.vue';

@Component({
    components: {
        VueSlider,
        ValidationProvider,
        NumberInput
    }
})
export default class MultiStepFormRangeInput extends Vue {
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) name: string;
    @Prop({ default: 0 }) min: number;
    @Prop({ default: 1000 }) max: number;
    @Prop({ default: 10 }) interval: number;
    @Prop({ required: true }) stepIndex: number;
    @Prop() unit: string;

    @Inject()
    model: Record<string, unknown>;

    @InjectReactive()
    currentStep: number;

    created(): void {
        if (!this.model[this.name]) {
            Vue.set(this.model, this.name, ((this.max - this.min) / 2) + this.min);
        }
    }

    get validationDisabled(): boolean {
        return this.currentStep !== this.stepIndex;
    }

    get options(): CurrencyInputOptions {
        return formattingOptions;
    }

    get _min(): string {
        return new Intl.NumberFormat().format(this.min);
    }

    get _max(): string {
        return new Intl.NumberFormat().format(this.max);
    }

    get rules(): Record<string, unknown> {
        /* eslint-disable */
        return {
            min_value: this.min,
            max_value: this.max,
            required: true
        };
        /* eslint-enable */
    }
}
