


















































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon, Dropdown, DropdownItem, CollapseTransition }
})
export default class SearchableGroupDropdown extends Vue {
    @Prop({ default: {} }) groups: Record<string, string[]>;
    @Prop() value: string;

    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: '' }) searchLabel: string;

    openGroups: string[] = [];
    currentSelection = '';
    currentSearch = '';

    mounted(): void {
        if (Object.keys(this.groups).length > 0) {
            this.openGroup(Object.keys(this.groups)[0]);
        }
        // respect the initial v-model value
        if (this.value) {
            this.currentSelection = this.value;
        }
    }

    protected shouldGroupRender(group: string): boolean {
        if (!this.currentSearch) return true;
        return this.groups[group].some(item => this.shouldItemRender(item));
    }

    protected shouldItemRender(item: string): boolean {
        if (item === '​') return false;
        if (!this.currentSearch) return true;
        return item.toLowerCase().includes(this.currentSearch.toLowerCase());
    }

    protected unsetValue(): void {
        this.currentSelection = '';
        this.currentSearch = '';
    }

    protected toggleGroup(group: string) {
        if (group) {
            this.isGroupOpen(group) ? this.closeGroup(group) : this.openGroup(group);
        }
    }

    protected openGroup(group: string): void {
        if (group) {
            this.openGroups.push(group);
        }
    }

    protected closeGroup(group: string): void {
        if (group) {
            this.openGroups.splice(this.openGroups.indexOf(group), 1);
        }
    }

    protected isGroupOpen(group: string): boolean {
        return group ? this.openGroups.includes(group) : false;
    }

    @Watch('currentSelection')
    watchSelection(newVal: string): void {
        this.$emit('input', newVal);
    }

    @Watch('value')
    watchValue(): void {
        this.currentSelection = this.value;
    }
}
