


































































































































































































































import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import gsap from 'gsap';
import VideoModal from './VideoModal.vue';
import PlayButton from './PlayButton.vue';

const playRadius = 3.14159 * (30 * 2); // Play button svg radius
const easeOut = 'Power4.out';

@Component({
    components: {
        VideoModal,
        PlayButton
    }
})
export default class StageVideoTeaser extends Vue {
    @Prop() bgVideoLink: string;
    @Prop() seeLabel: string;
    @Prop() see2Label: string;
    @Prop() watchLabel: string;
    @Prop() locales: string[];
    @Prop({ required: true }) language!: string;
    @Prop() title: string;
    @Prop() subTitle: string;
    @Prop() video: string;
    @Prop() link: string;
    @Prop({ default: '_blank' }) linkTarget: string;
    @Prop({ default: false, type: Boolean }) hideLangSwitch: boolean;
    @Prop({ default: false, type: Boolean }) hideScrollDown: boolean;
    @Prop({ default: false, type: Boolean }) hideGradient: boolean;
    @Prop() editMode: boolean;
    @Prop({ required: true }) accountId: string;
    @Prop({ required: true }) playerId: string;

    @Ref() wrapper: HTMLElement;

    currentLang = this.language;
    currentVideo = null;
    active = false;
    tl = null;
    overlayOpen = false;

    mounted(): void {
        const { title, playButton, playBorder, note, watchVideo, scrollIndicator } = this.$refs;
        this.active = true;
        this.tl = gsap.timeline({ paused: false });
        this.tl.fromTo(title, { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1.0, ease: easeOut }, 0.8);

        if (!this.hideLangSwitch && this.$refs.languageSelect) {
            this.tl.fromTo(this.$refs.languageSelect, { opacity: 0, y: -20 }, {
                opacity: 1,
                y: 0,
                duration: 1.0,
                ease: easeOut
            }, 1.1);
        }

        this.tl.fromTo(playButton, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.3, ease: easeOut }, 1.6);
        this.tl.fromTo(
            playBorder,
            { strokeDasharray: playRadius, strokeDashoffset: playRadius },
            { strokeDashoffset: 0, duration: 0.5 },
            1.6
        );

        this.tl.fromTo(note, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.6, ease: easeOut }, 1.4);
        this.tl.fromTo(watchVideo, { opacity: 0, y: 15 }, { opacity: 1, y: 0, duration: 0.6, ease: easeOut }, 1.6);

        this.tl.fromTo(scrollIndicator, { opacity: 0 }, { opacity: 1, duration: 0.6 }, 1.9);
        this.animateScrollDown(scrollIndicator);
    }

    openVideoModal(): void {
        if (this.video) {
            this.currentVideo = this.video;
        } else if (this.link) {
            window.open(this.link, this.linkTarget).focus();
        }
    }

    openOverlay(): void {
        this.overlayOpen = !this.overlayOpen;
    }

    scrollDown(): void {
        if (!this.hideScrollDown) {
            window.scroll({
                top: this.wrapper.clientHeight,
                behavior: 'smooth'
            });
        }
    }

    animateScrollDown(element): void {
        const tl = gsap.timeline({ paused: false, repeat: -1 });

        const circles: HTMLCollection = element.querySelectorAll('circle');
        const arrow = element.querySelector('path');
        let timingOffset = 0;

        Array.from(circles).forEach(circle => {
            tl.to(circle, { opacity: 0, duration: 0.2 }, timingOffset);
            tl.to(circle, { opacity: 1, duration: 0.2 }, timingOffset + 0.2);
            timingOffset += 0.2;
        });

        tl.to(arrow, { y: 3, duration: 0.5 }, 0.0);
        tl.to(arrow, { y: 0, duration: 0.5 }, 0.5);
    }

    @Watch('currentLang')
    onLangChange() {
        const path = window.location.pathname;
        const localeSegment = `/${this.currentLang}/`;
        window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
    }
}
