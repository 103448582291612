








import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';

@Component
export default class MainNavigation extends Vue {
    navStore: NavigationStore = getModule(NavigationStore);
    hasOpenSubMenu = false;

    get navigationOpened(): boolean {
        return this.navStore.navigationOpened;
    }

    get globalNavOpened(): boolean {
        return this.navStore.globalNavOpened;
    }

    toggleGlobalNavigation(): void {
        this.navStore.setGlobalNavOpened(!this.globalNavOpened);
        // shouldn't be possible to open multiple navigations/overlays at the same time
        if (this.globalNavOpened) {
            this.navStore.setNavigationOpened(false);
            this.navStore.setSearchOpened(false);
        }
    }
}
