







































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Article, ArticleLabels } from './ArticleFilterGrid.vue';
import TabHeader from '../base/TabHeader.vue';
import ArrowButton from '../base/ArrowButton.vue';
import Overlay from '../teaser/Overlay.vue';
import TeaserLink from '../teaser/TeaserLink.vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';

@Component({
    components: {
        TabHeader,
        ArrowButton,
        Overlay,
        TeaserLink
    }
})
export default class ArticleTabbedGrid extends Vue {
    @Prop({ default: false }) highlight!: boolean;
    @Prop({ default: 24 }) maxItems!: number;
    @Prop({ default: 18 }) chunkSize!: number;
    @Prop({ default: {} }) labels: ArticleLabels;
    @Prop({ default: [] }) articles!: Article[];
    @Prop({ default: {} }) categories: Record<string, Record<string, string>[]>;
    @Prop({ required: true }) uuid: string;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION_V2 + Enums.API.ARTICLES;

    shownArticles: Article[] = [];
    currentTag = 0;
    targetUrl = '';
    init = false;
    hasMore = false;
    offset = 0;

    created(): void {
        this.shownArticles = this.articles;
        this.offset = this.shownArticles.length;
        this.hasMore = this.shownArticles.length === this.maxItems;
        this.setTagFromQuery();
    }

    getUrl(limit: number): string {
        return `${this.apiUrl}uuid=${this.uuid}&locale=${this.$lang}${this.currentTag > 0 ? '&tag=' + this.tags[this.currentTag].uuid : ''}&offset=${this.offset}&limit=${limit}`;
    }

    /**
     * Since we can only use one tag category in this layout, we use the first one
     */
    get tags(): Record<string, string>[] {
        const tagCategories = Object.keys(this.categories);
        if (tagCategories.length > 0) {
            const tags = this.categories[tagCategories[0]];
            tags.unshift({
                uuid: '0',
                tag: this.labels.defaultFilter
            });
            return tags;
        }
        return [];
    }

    setTagFromQuery(): void {
        const params = new URLSearchParams(window.location.search);
        if (params.has('tag')) {
            const tag = params.get('tag');
            const index = this.tags.findIndex(t => t.uuid === tag);
            if (index >= 0) {
                this.currentTag = index;
            }
        }
    }

    getIcon(type: string): string {
        return type === 'overlay' ? type : 'right';
    }

    getArticles(limit: number): void {
        axios.get(this.getUrl(limit)).then(res => {
            if (this.offset === 0) {
                this.shownArticles = res.data;
            } else {
                this.shownArticles = [
                    ...this.shownArticles,
                    ...res.data
                ];
            }
            this.offset += limit;
            // if number of delivered articles is same as limit, assume there are more articles to load
            this.hasMore = res.data.length === limit;
        });
    }

    loadMore(): void {
        this.getArticles(this.chunkSize);
    }

    @Watch('currentTag')
    watchCurrentTag(newVal: number): void {
        if (newVal > 0) {
            this.init = true;
        }
        if (this.init) {
            this.offset = 0;
            this.getArticles(this.maxItems);
        }
    }
}
