



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component
export default class ContextMenu extends Vue {
    @Prop({ default: () => [] }) items: Record<string, string>[];

    selected = '';

    visible = false;

    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
    }

    @Emit('selected')
    select(value: string): string {
        this.visible = false;
        this.selected = value;
        return this.selected;
    }

    toggle(): void {
        this.visible = !this.visible;
    }

    onOutsideClick(evt: Event) {
        let targetElement = evt.target as HTMLElement;
        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentElement;
        } while (targetElement);
        this.visible = false;
    }
}
