


























import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: {
        ValidationProvider
    }
})
export default class VueFormCheckbox extends Vue {
    @Prop({ required: true }) name: string;
    @Prop({ default: false, type: Boolean }) required: boolean;
    @Prop() textClasses: string;

    @VModel() model: boolean;

    get rules(): Record<string, unknown> {
        return {
            required: this.required ? { allowFalse: false } : false
        };
    }

    get labelClasses(): string {
        if (this.textClasses) {
            return this.textClasses;
        } else {
            return 'text-para-xs pl-5';
        }
    }
}

