








import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { gsap } from 'gsap';
import Timeline = gsap.core.Timeline;

@Component
export default class Overlay extends Vue {
    @Prop() url: string;

    @Ref() dialogEL: HTMLDialogElement;
    @Ref() embedded: HTMLDivElement;

    mounted(): void {
        // append the dialog itself to the doc body
        // background: images might be placed in a slider which uses transformations which will break 'position: fixed'
        document.body.appendChild(this.dialogEL);
    }

    beforeDestroy(): void {
        // upon destroying the component, move the lightbox back, otherwise it will be stuck in the dom
        this.$el.appendChild(this.dialogEL);
    }

    @Watch('url')
    triggerModal() {
        this.url.length > 0 ? this.show() : this.hide();
    }

    /**
     * Actually fetches the dialog content.
     */
    fetchContent(): Promise<Element> {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            fetch(this.url)
                .then(res => res.text())
                .then(html => {
                    // insert the fetched html into the dom

                    const mount = {
                        props: [],
                        template: html
                    };
                    const VueMounted = Vue.extend(mount);
                    const vm = new VueMounted().$mount();
                    this.embedded.appendChild(vm.$el);

                    this.$nextTick(() => {
                        // get the inserted html as element
                        resolve(vm.$el);
                    });
                })
                .catch(reject);
        });
    }

    async show() {
        try {
            await this.fetchContent();

            const tl: Timeline = gsap.timeline({ paused: true });
            tl.fromTo('.js-content-modal-bg', { height: 0 }, { height: '100%', duration: 1.0 }, 0.0);
            tl.fromTo('.js-content-modal-content', { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 0.4 }, 0.3);
            tl.fromTo('.js-content-modal-copy', { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.5 }, 0.6);
            tl.restart();

            document.body.style.overflow = 'hidden';

            // get targets for open/close event
            const closeBtn: HTMLButtonElement = this.embedded.querySelector('.js-close-button');
            // and add the listeners
            closeBtn.addEventListener('click', this.hide);
            closeBtn.focus();
            // make sure hide method is called when dialog is closed by pressing escape
            this.dialogEL.addEventListener('close', this.hide);

            if (this.dialogEL && !this.dialogEL.open) {
                this.dialogEL.showModal();
            }
        } catch (err) {
            console.log(err);
        }
    }

    hide() {
        this.$emit('close');

        document.body.style.overflow = '';

        this.dialogEL.removeEventListener('close', this.hide);

        if (this.dialogEL.open) {
            this.dialogEL.close();
        }

        window.requestAnimationFrame(() => {
            // Timeout matches the animation time for it to fade.
            setTimeout(() => {
                this.embedded.innerHTML = '';
            }, 300);
        });
    }
}
