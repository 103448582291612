






















































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import Icon from '../base/Icon.vue';
import Sortable from '../tables/Sortable';
import GcButton from '../base/GcButton.vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';

const ALLOWED_TYPES = ['ai', 'doc', 'docx', 'eps', 'file', 'indd', 'jpg', 'jpeg', 'pdf', 'png', 'ps', 'psd', 'svg', 'xls', 'xlsx', 'xml', 'zip'];

@Component({
    components: {
        GcButton,
        Icon
    }
})
export default class DocumentsTable extends Mixins(Sortable) {
    @Prop({ required: true }) options: Record<string, any>;

    currentPage = 1;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION + Enums.API.DOCUMENTS;

    downloads = [];

    defaultOptions = {
        columns: [],
        order: 'ascending',
        orderBy: 'name',
        showHeaders: true,
        showSize: true,
        showIcon: true,
        dateAfterText: false
    };

    determineTarget(link: string): string {
        return link && link.indexOf('download=true') > 0 ? '_self' : '_blank';
    }

    get multiDownloadsActive(): boolean {
        return this.opts.columns.findIndex(c => c.key === 'check') >= 0;
    }

    get downloadsUrl(): string {
        const params = new URLSearchParams();
        this.downloads.forEach(k => params.append('key', k));
        return this.apiUrl + '?' + params.toString();
    }

    get opts(): Record<string, any> {
        return { ...this.defaultOptions, ...this.options };
    }

    get hasMore(): boolean {
        return (this.opts.batchSize > 0) && (this.items.length > (this.currentPage * this.opts.batchSize));
    }

    getTagsForCategory(file: Record<string, any>, category: string): string {
        const tags = (file.tags || []).filter(t => this.options.categories[category].indexOf(t) >= 0).sort();
        return tags.join(', ');
    }

    loadMore(): void {
        this.currentPage++;
    }

    rowClick(id: any, ev: MouseEvent): void {
        if (this.multiDownloadsActive) {
            this.toggleSelectedForDownload(id);
        } else {
            // block the original event as it might be doing the exact same thing as we're going to do next
            ev.preventDefault();

            if (ev.ctrlKey || ev.metaKey) {
                return; // blocked
            }

            const el: HTMLElement = ev.currentTarget as HTMLElement;

            if (el instanceof HTMLTableRowElement) {
                const anchors: HTMLCollectionOf<HTMLAnchorElement> = el.getElementsByTagName('a');

                if (anchors && anchors.length > 0) {
                    const a = anchors[0] as HTMLAnchorElement;
                    window.open(a.href, '_blank');
                }
            }
        }
    }

    tableHeaderStyle(column): string {
        if (column === 'title') {
            if (this.opts.columns.find(c => c.key === 'date')) {
                return 'font-semibold w-80 md:w-7/12 lg:w-8/12 text-left min-w-80';
            }
            return 'font-semibold w-80 md:w-9/12 lg:w-10/12 text-left min-w-80';
        }
        if (column === 'date') {
            return 'font-semibold w-24';
        }
        if (column === 'format') {
            return 'font-semibold pl-1';
        }
        return '';
    }

    downloadSelectedDocuments(): void {
        if (this.downloads.length > 0) {
            window.open(this.downloadsUrl, '_blank').focus();
            this.trackDownloads();
            this.downloads = [];
        }
    }

    toggleSelectedForDownload(id: string): void {
        if (this.multiDownloadsActive) {
            // either delete or add id to downloads list
            const index = this.downloads.indexOf(id);
            if (index >= 0) {
                this.downloads.splice(index, 1);
            } else {
                this.downloads.push(id);
            }
        }
    }

    isSelected(id: string): boolean {
        return this.downloads.indexOf(id) >= 0;
    }

    fileType(link: string): string {
        return DocumentsTable.getFileType(link);
    }

    trackDownloads(): void {
        if (this.$gtm && this.$gtm.enabled()) {
            window.dataLayer.push({
                event: 'multi_file_download',
                files: this.downloads.map(dl => this.items.find(i => i.id === dl)).filter(dl => !!dl).map(dl => dl.fileName)
            });
        }
    }

    static getFileType(link: string): string {
        // strip parameters
        const stripped = link.lastIndexOf('?') > 0 ? link.substring(0, link.lastIndexOf('?')) : link;
        // check for allowed file types or otherwise unsupported types will cause no icon to display at all
        let ending = stripped.split('.').length > 0 ? stripped.split('.').pop().toLowerCase() : 'file';
        if (ALLOWED_TYPES.indexOf(ending.toLowerCase()) < 0) {
            ending = 'file';
        }
        return ending;
    }

    @Watch('items')
    watchItems(): void {
        this.currentPage = 1;
    }
}
