






















import Vue from 'vue';
import Component from 'vue-class-component';
import EventsTable from './EventsTable.vue';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import EventsYear from './EventsYear.vue';
import Pagination from '../base/Pagination.vue';
import TabHeader from '../base/TabHeader.vue';

export interface Filter {
    type: string;
    label: string;
    options: string[];
    default: string;
}

export interface EventsLabels {
    filter: string;
    upcoming: string;
    previous: string;
    date: string;
    event: string;
    alert: string;
    addToCalendar: string;
}

export interface CalendarEvent {
    dateFrom: string;
    dateTo: string;
    icsLink: string;
    labelAddToCalendar: string;
    link: string;
    location: string;
    title: string;
}

@Component({
    components: {
        TabHeader,
        EventsYear,
        EventsTable,
        Pagination
    }
})
export default class EventsSection extends Vue {
    @Prop({ default: () => [] }) filters: Filter[];
    @Prop({ default: () => [] }) tags: string[];
    @Prop({ default: () => [] }) exclude: string[];
    @Prop({ default: {} }) labels: EventsLabels;
    @Prop({ default: 10 }) itemsPerPage: number;
    @Prop({ default: false }) inContentGroup: boolean;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_EVENT, this.$site);

    currentFilter = 0;
    currentYear = '';
    currentPage = 1;
    pages = 1;
    loading = false;

    events: CalendarEvent[] = [];

    get filterShown(): boolean {
        return this.currentFilter === 1;
    }

    get tabs(): string[] {
        return this.filters.map(f => f.label);
    }

    get yearOptions(): string[] {
        if (this.filters[this.currentFilter]) {
            return this.filters[this.currentFilter].options;
        }
        return [];
    }

    get tagsQuery(): string {
        return this.tags.map(tag => `&tags=${tag}`).join('');
    }

    get excludedQuery(): string {
        return this.exclude.map(tag => `&excluded=${tag}`).join('');
    }

    created(): void {
        if (this.filters.length > 0) {
            this.currentYear = this.filters[0].default;
        }
    }

    getEvents(): void {
        const url = `${this.apiUrl}upcoming=${this.currentFilter === 0}&offset=${(this.currentPage - 1) * this.itemsPerPage}&limit=${this.itemsPerPage}` +
                    `&year=${this.currentYear}&reload=true&locale=${Utils.getLocalStorage(Enums.STORAGE_KEY.LANGUAGE)}` +
                    `${this.tagsQuery}${this.excludedQuery}`;
        Utils.getAPI(url).then(data => {
            this.events = data.content;
            this.pages = data.pages === -1 ? this.pages : data.pages;
            this.loading = false;
        });
    }

    @Watch('currentFilter')
    watchCurrentFilter(): void {
        this.loading = true;
        const defaultYear = this.yearOptions.length > 0 ? this.yearOptions[0] : '';
        // prevent unnecessary api calls
        const fetch = defaultYear === this.currentYear && !!this.currentYear;
        if (fetch) {
            this.currentPage = 1;
            this.getEvents();
        } else {
            this.currentYear = defaultYear;
        }
    }

    @Watch('currentYear')
    watchCurrentYear(): void {
        this.currentPage = 1;
        this.getEvents();
    }

    @Watch('currentPage')
    watchCurrentPage(): void {
        this.getEvents();
    }
}
