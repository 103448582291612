























































import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import LoadSpinner from './LoadSpinner.vue';
import StageTeaser from './StageTeaser.vue';
import ResponsiveImage from '../base/ResponsiveImage.vue';

@Component({
    components: { LoadSpinner, ResponsiveImage }
})
export default class StageCarousel extends Vue {
    @Provide('registerCarouselItem') childRegistration = this.register;

    items: InstanceType<typeof StageTeaser>[] = [];
    index = 0;
    interval = null;

    beforeDestroy() {
        clearInterval(this.interval);
    }

    register(item: InstanceType<typeof StageTeaser>) {
        this.items.push(item);
        if (this.$children.filter(this.filterItems).length === this.items.length) {
            this.start();
        }
    }

    filterItems(child: Vue) {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'stage-teaser';
    }

    start() {
        this.interval = setInterval(() => {
            if (this.index + 1 < this.items.length) {
                this.index++;
            } else {
                this.index = 0;
            }
        }, 4500);
    }

    get currentItem(): InstanceType<typeof StageTeaser> {
        if (this.items.length > this.index) {
            return this.items[this.index];
        }
        return null;
    }
}
