




































import Vue from 'vue';
import { Component, Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
    components: {
        ValidationProvider
    }
})
export default class MultiStepFormSelect extends Vue {
    @Prop({ required: true }) name: string;
    @Prop({ required: true }) title: string;
    @Prop() suffix: string;
    @Prop({ default: '' }) placeholder: string;
    @Prop() defaultValue: string;
    @Prop({ default: false }) required: boolean;
    @Prop({ default: false }) searchable: boolean;
    @Prop({ default: [] }) labels: string[];
    @Prop({ required: true }) stepIndex: number;

    @Inject()
    model: Record<string, unknown>;

    @InjectReactive()
    currentStep: number;

    created(): void {
        if (!this.model[this.name] && this.defaultValue) {
            Vue.set(this.model, this.name, this.defaultValue);
        }
    }

    get validationDisabled(): boolean {
        return this.currentStep !== this.stepIndex;
    }

    get options(): Record<string, string> {
        const result = {};
        if (this.labels) {
            this.labels.forEach(s => {
                const keyValue = s.split(':');
                if (keyValue.length === 2) {
                    result[keyValue[1]] = keyValue[0];
                }
            });
        }
        return result;
    }

    get rules(): Record<string, unknown> {
        /* eslint-disable */
        return {
            required: this.required
        };
        /* eslint-enable */
    }
}
