















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Combobox from '../base/Combobox.vue';

@Component({
    components: {
        Combobox,
        ValidationProvider
    }
})
export default class VueFormSelection extends Vue {
    @Prop() name: string;
    @Prop() title: string;
    @Prop() description: string;
    @Prop() defaultValue: string;
    @Prop() placeholder: string;
    @Prop({ default: 'text' }) type: string;
    @Prop({ default: false, type: Boolean }) required: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop({ default: false, type: Boolean }) horizontal: boolean;
    @Prop({ default: false, type: Boolean }) searchable: boolean;
    @Prop({ default: false, type: Boolean }) hideTitle: boolean;
    @Prop({ default: false, type: Boolean }) pillItems: boolean;
    @Prop({ default: '*' }) requiredSymbol: string;
    @Prop() labels: string[];
    @Prop() value: any;

    @Ref() provider: any;

    model: any = '';

    get rules(): Record<string, unknown> {
        return {
            required: this.required
        };
    }

    get options(): Record<string, string> {
        const result = {};
        if (this.labels) {
            this.labels.forEach(s => {
                const keyValue = s.split(':');
                if (keyValue.length === 2) {
                    result[keyValue[1]] = keyValue[0];
                }
            });
        }
        return result;
    }

    mounted(): void {
        if (typeof this.value !== 'undefined') {
            // enables using a boolean as v-model
            if (typeof this.value === 'boolean') {
                this.model = String(this.value);
            } else {
                this.model = this.value;
            }
        } else if (this.type === 'checkbox') {
            // checkbox needs array
            const tmpModel = [];
            if (this.defaultValue) {
                const split = this.defaultValue.split('__');
                split.forEach(s => tmpModel.push(s));
            }
            this.model = tmpModel;
        } else if (this.defaultValue) {
            this.model = this.defaultValue;
        }
    }

    getDescribedById(errors: string[]) {
        if (errors.length > 0) {
            return `${this.name}-error`;
        } else if (this.description) {
            return `${this.name}-description`;
        }
        return '';
    }

    @Watch('model')
    watchModel() {
        this.$emit('input', this.model);
    }

    @Watch('value')
    watchValue(newVal: any) {
        this.model = newVal;
    }
}
