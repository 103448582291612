













































































import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import Vue from 'vue';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import { Region, Operation, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Icon,
        Dropdown,
        DropdownItem,
        CollapseTransition
    }
})
export default class SocioEconomicMapSelection extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ default: () => ([]) }) groups: Array<Operation|Region>;
    @Prop({ required: true }) scope: string;
    @Prop({ default: null }) currentQuery: string;
    @Prop({ default: null }) currentOperation: SubOperation;
    @Prop({ default: null }) currentRegion: SubRegion;

    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: '' }) searchLabel: string;

    @Ref() searchField

    /**
     * Whenever a region or an operation has been set the other selection needs to be nulled.
     */
    @Watch('currentQuery')
    watchCurrentQuery() {
        if (this.currentQuery !== this.scope) {
            // Unset
            this.currentSelection = null;
            this.currentSearch = '';
            (this.$children.find(child => child.$vnode.componentOptions.tag === 'dropdown') as any)
                ._data.activeValue = this.scope;
            (this.$children.find(child => child.$vnode.componentOptions.tag === 'dropdown') as any)
                ._data.activeTitle = this.scope;
        } else {
            // Set
            this.currentSelection = null;
            this.currentSearch = '';
            (this.$children.find(child => child.$vnode.componentOptions.tag === 'dropdown') as any)
                ._data.activeValue = this.scope === 'Operation' ? this.currentOperation : this.currentRegion;
            (this.$children.find(child => child.$vnode.componentOptions.tag === 'dropdown') as any)
                ._data.activeTitle = this.scope === 'Operation'
                    ? (this.currentOperation.title || this.currentOperation.name)
                    : (this.currentRegion.title || this.currentRegion.name);
        }
    }

    /***********************
     * Vue Data
     ***********************/

    private currentSelection: SubOperation|SubRegion = null;
    private currentSearch = '';
    private openGroups: Array<string> = [];

    /***********************
     * Vue Lifecycle
     ***********************/
    mounted() {
        this.openGroup(this.groups[0]);
    }

    /***********************
     * Vue Methods
     ***********************/

    private shouldItemRender(item: SubOperation|SubRegion): boolean {
        return (
            item.title.includes(this.currentSearch) ||
            item.title.toUpperCase().includes(this.currentSearch.toUpperCase()) ||
            item.title.toLowerCase().includes(this.currentSearch.toLowerCase()) ||
            item.name.includes(this.currentSearch) ||
            item.name.toUpperCase().includes(this.currentSearch.toUpperCase()) ||
            item.name.toUpperCase().includes(this.currentSearch.toLowerCase())
        );
    }

    private setQuery(item: SubOperation|SubRegion): void {
        this.$emit(`set${this.scope}`, item);
    }

    private unsetQuery(): void {
        this.$emit('unset');
    }

    private highlightSearch(string: string): string {
        return `${string.slice(0, this.getStartIndex(string))}<strong>${string.slice(this.getStartIndex(string), this.getStartIndex(string) + this.currentSearch.length)}</strong>${string.slice(this.getStartIndex(string) + this.currentSearch.length, string.length)}`;
    }

    private getStartIndex(string: string): number {
        if (string.indexOf(this.currentSearch) > -1) return string.indexOf(this.currentSearch);
        if (string.toLowerCase().indexOf(this.currentSearch) > -1) return string.toLowerCase().indexOf(this.currentSearch);
        if (string.toUpperCase().indexOf(this.currentSearch) > -1) return string.toUpperCase().indexOf(this.currentSearch);
        return -1;
    }

    private isSingle(group: Operation|Region) {
        return group.items.length < 1;
    }

    private handleGroupClick(group: Operation|Region) {
        if (group.items.length > 0) {
            this.toggleGroup(group);
        } else {
            this.setQuery(group as any as SubOperation|SubRegion);
        }
    }

    private toggleGroup(group: Operation|Region) {
        if (group) {
            this.isGroupOpen(group) ? this.closeGroup(group) : this.openGroup(group);
        }
    }

    private openGroup(group: Operation|Region): void {
        if (group) {
            this.openGroups.push(group.uuid);
        }
    }

    private closeGroup(group: Operation|Region): void {
        if (group) {
            this.openGroups.splice(this.openGroups.indexOf(group.uuid), 1);
        }
    }

    private isGroupOpen(group: Operation|Region): boolean {
        return group ? this.openGroups.includes(group.uuid) : false;
    }

    private focusSearch(): void {
        this.$nextTick(() => {
            if (this.searchField) {
                this.searchField.focus({ preventScroll: true });
            }
        });
    }

    /***********************
     * Vue Computed
     ***********************/

    get allItems() {
        const result: Array<SubOperation|SubRegion> = [];
        this.groups.forEach(group => {
            group.items.forEach(item => {
                result.push(item);
            });
        });
        return result;
    }

    get dropdownTextClasses() {
        return this.currentSelection !== null ? 'text-para-xs md:text-para-s h-4 md:h-6 text-black-500' : 'text-para-xs md:text-para-s h-4 md:h-6 text-black-200';
    }
}
