



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import TabHeader from '../base/TabHeader.vue';
import WindChart from './WindChart.vue';
import TableView from './TableView.vue';
import GraphView from './GraphView.vue';

export interface MonitoringLabels {
    col1: string;
    col2: string;
    col3: string;
    col4: string;
    min5Value: string;
    min5Time: string;
    hourlyValue: string;
    hourlyTime: string;
    tableView: string;
    graphView: string;
    dataCollected: string;
    legend: string;
}

export interface Scale {
    label: string;
    min: number;
    max?: number;
    color: string;
}

export interface SnapshotValue {
    station: string;
    value: string;
    time: string;
    interval: string;
    datatype: string;
}

export interface DataPoint {
    label: string;
    value5?: number;
    value5Time?: string;
    value60: number;
    value60Time?: string;
}

export interface WindDataPoint {
    label: string;
    direction: number;
    speed: number;
}

@Component({
    components: {
        GraphView,
        TabHeader,
        TableView,
        WindChart
    }
})
export default class MonitoringGraph extends Vue {
    @Prop({ required: true }) labels: MonitoringLabels;
    @Prop({ required: true }) scale: Scale[];
    @Prop({ default: 1000 }) maxValue: number;
    @Prop({ default: false, type: Boolean }) hideTrafficLight: boolean;
    @Prop({ default: false, type: Boolean }) inContentGroup: boolean;

    apiUrl = Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.SO2;

    data: DataPoint[] = [];
    windData: WindDataPoint[] = [];
    tabModel = 0;

    get tabs(): string[] {
        return [
            this.labels.tableView,
            this.labels.graphView
        ];
    }

    created(): void {
        this.getSnapshotData();
    }

    getSnapshotData(): void {
        const url = `${this.apiUrl}/snapshot/sudbury/`;
        axios.get(url)
            .then(res => {
                const data: SnapshotValue[] = res.data;
                // get unique stations
                const so2Stations = data.filter(d => d.datatype === 'SO2').map(s => s.station).filter((s, i, a) => a.indexOf(s) === i);
                const windStations = data.filter(d => d.datatype === 'Wind Speed').map(s => s.station).filter((s, i, a) => a.indexOf(s) === i);
                // create data entries
                so2Stations.forEach(so2 => {
                    const value60 = data.find(d => d.station === so2 && d.interval === 'Hourly');
                    const value5 = data.find(d => d.station === so2 && d.interval !== 'Hourly');
                    this.data.push({
                        label: so2,
                        value5: parseFloat(value5?.value || '0'),
                        value5Time: value5?.time || '-',
                        value60: parseFloat(value60?.value || '0'),
                        value60Time: value60?.time || '-'
                    });
                });
                windStations.forEach(w => {
                    const dir = data.find(d => d.station === w && d.datatype === 'Wind Dir')?.value || '0';
                    const speed = data.find(d => d.station === w && d.datatype === 'Wind Speed')?.value || '0';
                    this.windData.push({
                        label: w,
                        direction: parseFloat(dir),
                        speed: parseFloat(speed)
                    });
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    getDate(): string {
        const date = new Date();
        // convert numbers to double digits
        const dd = num => (num < 10 ? `0${num}` : `${num}`);
        return `${dd(date.getDate())}/${dd(date.getMonth() + 1)}/${dd(date.getFullYear())} ${dd(date.getHours())}:${dd(date.getMinutes())}`;
    }
}
