


















































































import Checkbox from '../base/Checkbox.vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import SearchInputDropdown from '../base/SearchInputDropdown.vue';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        Checkbox,
        SearchInputDropdown,
        Icon
    }
})
export default class MapCategories extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop({ default: {} }) categories!: any;
    @Prop({ default: '' }) labelShowAll: string;
    @Prop({ default: '' }) labelSearch: string;
    @Prop({ default: '' }) labelBack: string;
    @Prop({ default: {} }) searchEntries: Record<string, string>

    /***********************
     * Vue Data
     ***********************/

    selectedCategories: Array<any> = [];
    searched = false;
    searchOpen = false;

    /***********************
     * Vue Methods
     ***********************/

    selectCategory(category): void {
        if (category.subCategories === undefined) {
            this.deselectParent(category);
            this.selectedCategories.push(category);
        } else {
            this.deselectChildren(category);
            this.selectedCategories.push(category);
        }
        this.searched = false;
        this.filter();
    }

    deselectCategory(category): void {
        this.selectedCategories.splice(this.selectedCategories.indexOf(category), 1);
        this.filter();
    }

    deselectChildren(category): void {
        category.subCategories.forEach(subCategory => {
            if (this.selectedCategories.includes(subCategory)) {
                this.selectedCategories.splice(this.selectedCategories.indexOf(subCategory), 1);
            }
        });
    }

    deselectParent(category): void {
        const parent = this.findParent(category);
        if (!this.selectedCategories.includes(parent)) return;
        this.selectedCategories.splice(this.selectedCategories.indexOf(parent), 1);
    }

    selectAllCategories(): void {
        this.selectedCategories = [];
        this.searched = false;
        this.filter();
    }

    findParent(category): any {
        for (const key of Object.keys(this.categories)) {
            if (this.categories[key].subCategories.includes(category)) {
                return this.categories[key];
            }
        }
    }

    filter(): void {
        this.$emit('filter-update', this.selectedCategories);
    }

    @Emit('searched')
    handleSearch(uuid: string): string {
        this.searched = true;
        this.searchOpen = false;
        this.selectedCategories = [];
        this.$emit('filter-update', this.selectedCategories);
        return uuid;
    }
}
