


























import Vue from 'vue';
import { Component, Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { CurrencyInputOptions } from 'vue-currency-input';
import NumberInput from '../../base/NumberInput.vue';
import { formattingOptions } from './MultiStepForm.vue';

@Component({
    components: {
        ValidationProvider,
        NumberInput
    }
})
export default class MultiStepFormNumberInput extends Vue {
    @Prop({ required: true }) title: string;
    @Prop({ required: true }) name: string;
    @Prop({ default: 0 }) default: number;
    @Prop() min: number;
    @Prop() max: number;
    @Prop({ default: false }) required: boolean;
    @Prop({ required: true }) stepIndex: number;

    @Inject()
    model: Record<string, unknown>;

    @InjectReactive()
    currentStep: number;

    created(): void {
        if (!this.model[this.name]) {
            Vue.set(this.model, this.name, this.default);
        }
    }

    get validationDisabled(): boolean {
        return this.currentStep !== this.stepIndex;
    }

    get options(): CurrencyInputOptions {
        return formattingOptions;
    }

    get rules(): Record<string, unknown> {
        /* eslint-disable */
        const rules = {
            is_number: true,
            required: this.required
        };
        if (typeof this.min !== 'undefined') {
            rules['min_value'] = this.min;
        }
        if (typeof this.max !== 'undefined') {
            rules['max_value'] = this.max;
        }
        /* eslint-enable */
        return rules;
    }
}
