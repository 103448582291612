



















import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';
import Icon from './Icon.vue';
import Lightbox from './Lightbox.vue';
import breakpoints from '../../plugins/breakpoints';

@Component({
    components: {
        Icon,
        Lightbox
    }
})
export default class SimpleImage extends Vue {
    @Prop({ required: true }) src: string;
    @Prop() content: string;
    @Prop() title: string;
    @Prop() labelledBy: string;
    @Prop() caption: string;
    @Prop() altTitle: string;
    @Prop({ default: 'w-full h-full' }) imgClass: string;
    @Prop({ default: false, type: Boolean }) showCaption: boolean;
    @Prop({ default: false, type: Boolean }) showLightbox: boolean;
    @Prop({ default: false, type: Boolean }) hideMobileLightboxIcon: boolean;

    lightboxVisible = false;

    get alt(): string {
        return this.getCleanText(this.altTitle || '');
    }

    get _showLightbox(): boolean {
        return this.showLightbox && breakpoints.mdAndUp;
    }

    get fileName(): string {
        if (this.src) {
            return this.src.split('/').pop();
        }
        return '';
    }

    protected getCleanText(text: string): string {
        const el = document.createElement('div');
        el.innerHTML = text;
        return el.innerText;
    }

    @Emit('click')
    protected setLightboxVisible(value: boolean): void {
        if (this._showLightbox) {
            this.lightboxVisible = value;
        }
    }
}
