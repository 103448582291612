













import Vue from 'vue';
import { Component, Inject, InjectReactive, Prop } from 'vue-property-decorator';

@Component
export default class DropdownItem extends Vue {
    @Prop() label: string;
    @Prop() value: string | number;
    @Prop({ default: false, type: Boolean }) selected: string;
    @Prop({ default: true, type: Boolean }) showLabel: boolean;
    @Prop({ default: 'text-para-s' }) textClasses!: string;

    @Inject({
        default: () => null
    }) readonly register!: Function;

    @InjectReactive()
    readonly currentSearch: string;

    get shouldRender(): boolean {
        if (this.label === '​') return false;
        if (!this.currentSearch) return true;
        return this.label.toLowerCase().includes(this.currentSearch.toLowerCase());
    }

    created(): void {
        // register self on parent component
        this.register(this);
    }
}
