











































import GmapCluster from 'gmap-vue/dist/components/cluster';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import styles from './mapStyles';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Office } from './WorldMap.vue';
import breakpoints from '../../plugins/breakpoints';
import isEqual from 'lodash.isequal';

@Component({
    components: { GmapCluster, GmapCustomMarker }
})
export default class MapEmbed extends Vue {
    /***********************
     * Vue Props
     ***********************/

    @Prop() markers: Array<any>;
    @Prop() singleMarker: any;
    @Prop() regionOption: any;
    @Prop({ default: false }) isOfficeMap: boolean;
    @Prop({ default: false }) isWorldMap: boolean;
    @Prop({ default: false }) hideCategories: boolean;
    @Prop({ default: {} }) selectedMarker: any;

    /***********************
     * Vue data
     ***********************/

    renderMarkers = true;
    currentBounds = {};

    zoom = 2;

    specialZoomLevels = {
        mobile: 1,
        tablet: 1.4,
        desktop: 2.2
    }

    center: any = { lat: 0, lng: 0 };

    bounds: any = {};

    mapOptions: any = {
        styles,
        minZoom: 1.5,
        maxZoom: 15,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false
    };

    clusterStyles: Array<any> = [{
        textColor: 'black',
        height: 38,
        fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '12px',
        fontWeight: '800',
        url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMyIDQwIiBmaWxsPSJub25lIj4KICAgIDxjaXJjbGUgY3g9IjE2IiBjeT0iMTYiIHI9IjE1IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxsaW5lIHgxPSIxNiIgeTE9IjMyIiB4Mj0iMTYiIHkyPSIzOSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4=',
        width: 32
    }]

    @Watch('markers')
    watchMarkers() {
        this.renderMarkers = false;
        /* eslint-disable */
        // @ts-ignore
        this.$gmapApiPromiseLazy().then(() => {
            if (this.markers.length) {
                /* eslint-disable */
                //@ts-ignore
                const bounds = new google.maps.LatLngBounds();
                const map = this.$refs.mapRef;
                this.markers.forEach(marker => bounds.extend(marker.position));
                //@ts-ignore
                map.fitBounds(bounds);
                /* eslint-enable */

                // if the bounds didn't change, set markers to visible at once since the @idle event won't fire
                if (isEqual(bounds, this.currentBounds)) {
                    this.renderMarkers = true;
                }
                this.currentBounds = bounds;
            }
        });
    }

    /***********************
     * Vue Lifecycle
     ***********************/

    created() {
        if (this.regionOption && !this.isWorldMap) {
            const { position: { lat, lng }, zoom } = this.regionOption;
            this.center.lat = lat;
            this.center.lng = lng;
            this.zoom = zoom;
        }
        if (this.hideCategories && this.isWorldMap) {
            this.zoom = this.responsiveZoom;
        }
    }

    /***********************
     * Vue Methods
     ***********************/

    openLocation(marker) {
        this.$emit('open-location', marker);
    }

    getMarkerStyle(marker) {
        return {
            borderColor: !this.isOfficeMap ? marker.category.color : '#00928E',
            backgroundColor: this.selectedMarker === marker ? !this.isOfficeMap ? marker.category.color : '#00928E' : 'white',
            color: this.selectedMarker === marker ? 'white' : 'black'
        };
    }

    setOffice(office: Office) {
        this.$emit('office-select', office);
    }

    /***********************
     * Vue Computed
     ***********************/

    get responsiveZoom(): number {
        return this.specialZoomLevels[this.breakpoint];
    }

    get breakpoint(): string {
        if (breakpoints.desktop) {
            return 'desktop';
        }
        if (breakpoints.tablet) {
            return 'tablet';
        } else {
            return 'mobile';
        }
    }

    get fitMap() {
        return this.isOfficeMap || (!this.isOfficeMap && !this.hideCategories);
    }
}
