










































































import Vue from 'vue';
import { Component, Prop, Provide, ProvideReactive, Ref } from 'vue-property-decorator';
import MultiStepFormHeader from './MultiStepFormHeader.vue';
import ArrowButton from '../../base/ArrowButton.vue';
import GcButton from '../../base/GcButton.vue';
import { ValidationObserver } from 'vee-validate';
import Icon from '../../base/Icon.vue';
import axios from 'axios';
import { CurrencyDisplay, CurrencyInputOptions } from 'vue-currency-input';
import LoadingSpinner from '../../base/LoadingSpinner.vue';
import Utils from '../../../utils/Utils';

export interface Step {
    title: string;
    type: 'normal' | 'intermediate';
}

export interface MultiStepFormLabels {
    next: string;
    back: string;
    submit: string;
}

export const formattingOptions: CurrencyInputOptions = {
    currency: 'EUR',
    currencyDisplay: CurrencyDisplay.hidden,
    autoDecimalDigits: false,
    useGrouping: true,
    precision: { min: 0, max: 2 },
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoSign: false
};

declare const d365mktformcapture: any;

@Component({
    components: {
        ValidationObserver,
        MultiStepFormHeader,
        LoadingSpinner,
        ArrowButton,
        GcButton,
        Icon
    }
})
export default class MultiStepForm extends Vue {
    @Prop({ default: 'form' }) name: string;
    @Prop({ required: true }) endpoint: string;
    @Prop({ required: true }) successMessage: string;
    @Prop({ required: true }) errorMessage: string;
    @Prop({ required: true }) labels: MultiStepFormLabels;
    @Prop({ default: false }) editMode: boolean;
    @Prop({ default: '' }) resultComponentId: string;
    @Prop() steps: Step[];
    @Prop({ default: false, type: Boolean }) dynamicsSend: boolean;
    @Prop() dynamicsFormId: string;
    @Prop() dynamicsApiUrl: string;
    @Prop() dynamicsLibUrl: string;
    @Prop({ default: {} }) dynamicsFieldMappings: any;

    @Ref() stepContainer: HTMLDivElement;

    @Provide()
    model: Record<string, unknown> = {};

    @ProvideReactive()
    currentStep = 0;

    @ProvideReactive()
    resultData: Record<string, any> = {};

    success = false;
    animateIcon = false;
    serverError = '';
    sending = false;

    /***********************
     * Vue lifecycle
     ***********************/

    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    }

    mounted(): void {
        window.addEventListener('resize', this.calcHeight);
        this.calcHeight();
        if (this.resultComponentId) {
            Vue.set(this.model, 'resultComponentId', this.resultComponentId);
        }
    }

    /***********************
     * Vue computed
     ***********************/

    get showBackNavigation(): boolean {
        return (this.currentStep > 0) && (this.currentStep < this.steps.length) && (this.steps.length > 1);
    }

    get lastStepTitle(): string {
        return this.currentStep > 0 ? this.steps[this.currentStep - 1]?.title || '' : '';
    }

    /***********************
     * Vue methods
     ***********************/

    @Provide()
    protected calcHeight(): void {
        if (!this.editMode) {
            let maxHeight = 0;
            this.stepContainer.childNodes.forEach((child: HTMLDivElement) => {
                if (child.clientHeight > maxHeight) maxHeight = child.clientHeight;
            });
            this.stepContainer.style.minHeight = `${maxHeight}px`;
        }
    }

    protected next(): void {
        if (this.currentStep === (this.steps.length - 1)) {
            this.submit();
        } else {
            this.currentStep++;
        }
    }

    protected previous(): void {
        this.currentStep--;
    }

    protected submit(): void {
        this.sending = true;

        if (this.dynamicsSend && !!d365mktformcapture) {
            // dynamics form capture
            const form = this.$el.querySelector('form');
            form.querySelectorAll('select').forEach((select: any) => {
                if (select.forEach) {
                    select.forEach = null;
                }
            });
            const serializedForm = d365mktformcapture.serializeForm(form, this.dynamicsFieldMappings);

            const payload = serializedForm.SerializedForm.build();
            const captureConfig = {
                FormId: this.dynamicsFormId,
                FormApiUrl: this.dynamicsApiUrl
            };

            d365mktformcapture.submitForm(captureConfig, payload)
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    // submit after promise is either accepted or rejected
                    this.doSend();
                });
        } else {
            // normal submit
            this.doSend();
        }
    }

    private doSend() {
        axios.post(this.endpoint, this.model)
            .then((res) => {
                this.resultData = res.data;
                this.success = true;
                this.currentStep++;
                setTimeout(() => {
                    this.animateIcon = true;
                }, 250);
            })
            .catch((error) => {
                if (error.response?.data) {
                    this.serverError = error.response.data;
                } else {
                    this.serverError = this.translate('request_error');
                }

                this.currentStep++;
                setTimeout(() => {
                    this.animateIcon = true;
                }, 250);
            })
            .finally(() => {
                this.sending = false;
            });
    }

    /**
     * returns the translation with the given key
     * logs an error and returns the key itself if it is not found
     */
    protected translate(key: string): string {
        const translation = this.labels.find(x => x.key === key);
        if (translation) {
            return translation.value;
        }
        // missing translations throw errors that are hard to track down
        console.error(`key "${key}" not found`);
        return key;
    }
}
