



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Icon from '../base/Icon.vue';
import { ValidationResult } from 'vee-validate/dist/types/types';

@Component({
    components: {
        ValidationProvider,
        Icon
    }
})
export default class VueFormInput extends Vue {
    @Prop() name: string;
    @Prop() title: string;
    @Prop() patternDescription: string;
    @Prop() description: string;
    @Prop() defaultValue: string;
    @Prop({ default: 'text' }) type: string;
    @Prop({ default: false }) readonly: boolean;
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: false }) autofocus: boolean;
    @Prop({ default: false }) required: boolean;
    @Prop({ default: false }) isError: boolean;
    @Prop({ default: '*' }) requiredSymbol: string;
    @Prop({ default: 'on' }) autocomplete: string;
    @Prop({ default: 1 }) rows: number;
    @Prop() placeholder: string;
    @Prop({ default: false, type: Boolean }) lowerCase: boolean;
    @Prop() rules: Record<string, unknown>;
    @Prop() value: any;

    @Ref() provider: InstanceType<typeof ValidationProvider>;

    model = '';

    mounted(): void {
        if (this.value) {
            this.model = this.value;
        } else if (this.defaultValue) {
            this.model = this.defaultValue;
        }
    }

    getClasses(errors: string[], passed: boolean): Record<string, boolean> {
        return {
            'pl-10': this.type === 'email',
            'pl-4': this.type !== 'email',
            'text-black-300 bg-black-50': this.disabled,
            'text-black-900': !this.disabled,
            'md:border-error': this.hasError(errors),
            'md:border-black-900': passed,
            'border-black-100': !this.hasError(errors) && !passed
        };
    }

    getIcon(errors: string[], passed: boolean): string {
        if (this.hasError(errors)) {
            return 'invalid';
        } else if (passed) {
            return 'valid';
        }
        return '';
    }

    hasError(errors: string[]) {
        return errors.length > 0 || this.isError;
    }

    getDescribedById(errors: string[]) {
        if (this.hasError(errors)) {
            return `${this.name}-error`;
        } else if (this.description) {
            return `${this.name}-description`;
        }
        return '';
    }

    @Watch('model')
    watchModel() {
        if (this.model && this.lowerCase) {
            this.model = this.model.toLowerCase();
        }
        this.$emit('input', this.model);
    }

    @Watch('value')
    watchValue(newVal: any) {
        this.model = newVal;
    }

    async onBlur(event: Event): Promise<void> {
        // trigger manual validation as the 'passed' variable will always be false on first call
        const result: ValidationResult = await this.provider.validate(event);
        this.$emit('blur', result.valid);
    }
}
