

















































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ArrowButton extends Vue {
    @Prop({ default: 'right' }) direction: string;
    @Prop({ default: false, type: Boolean }) showRingOnMobile: boolean;
    @Prop({ default: false, type: Boolean }) hideCircle: boolean;
}
