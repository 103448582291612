









import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class MainNavigationSubMenu extends Vue {
    @Prop({ required: true }) isOpened: boolean;

    hasOpenSubMenu = false;

    mounted() {
        if (this.isOpened) {
            this.notifyParent(true);
        }
    }

    notifyParent(open: boolean): void {
        // notify parent menu, that it has an opened sub menu
        if (typeof (this.$parent.$parent as any).hasOpenSubMenu === 'boolean') {
            (this.$parent.$parent as any).hasOpenSubMenu = open;
        }
    }

    @Watch('hasOpenSubMenu')
    watchHasOpenSubMenu(newVal: boolean): void {
        if (newVal) {
            this.notifyParent(true);
        }
    }

    @Watch('isOpened')
    watchIsOpened(newVal: boolean): void {
        this.notifyParent(newVal);
    }
}
