













































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Icon from '../base/Icon.vue';

@Component({
    components: {
        ValidationProvider,
        Icon
    }
})
export default class VueFormFile extends Vue {
    @Prop() name: string;
    @Prop() title: string;
    @Prop() description: string;
    @Prop() limitation: string;
    @Prop({ default: false }) isError: boolean;
    @Prop() rules: Record<string, unknown>;

    @Ref() provider: InstanceType<typeof ValidationProvider>;
    @Ref() input: HTMLInputElement;

    files: Record<string, string> = {};
    sizes: Record<string, number> = {};
    keys = [1];

    async onFileChange(ev: Event, key: number): Promise<void> {
        const validationResult = await this.provider[this.keys.indexOf(key)].validate(ev);
        if (validationResult.valid) {
            const target = ev.target as HTMLInputElement;
            Vue.set(this.files, key, target.files[0].name);
            Vue.set(this.sizes, key, target.files[0].size);
            // allow max 10 files
            if (this.keys.length < 10) {
                const current = this.keys.slice(-1)[0];
                this.keys.push(current + 1);
            }
        }
    }

    reset(key: number): void {
        this.input.value = '';
        Vue.delete(this.files, key);
        Vue.delete(this.sizes, key);
        if (this.keys.length > 1) {
            this.keys.splice(this.keys.indexOf(key), 1);
        }
    }

    hasFile(key: number): boolean {
        return Object.prototype.hasOwnProperty.call(this.files, key);
    }

    getFileSize(key: number): string {
        if (this.sizes[key] < 1000000) {
            return Math.round(this.sizes[key] / 1000) + ' KB';
        } else {
            return Math.round(this.sizes[key] / 1000000) + ' MB';
        }
    }
}
