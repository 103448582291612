













































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';
import ResponsiveImage from '../base/ResponsiveImage.vue';

@Component({
    components: {
        ResponsiveImage
    }
})
export default class StageSectionTeaser extends Vue {
    @Prop({ default: false, type: Boolean }) editMode: boolean;
    @Prop({ default: false, type: Boolean }) showGradient: boolean;
    @Prop({ default: false, type: Boolean }) showLanguageSwitcher: boolean;
    @Prop({ required: true }) language!: string;
    @Prop({ default: {} }) locales: Record<string, string>;

    @Prop() title: string;
    @Prop() subTitle: string;
    @Prop() description: string;
    @Prop() languageSwitcherPrefix: string;
    @Prop() languageSwitcherSuffix: string;

    @Prop() video: string
    @Prop() poster: string

    @Prop({ required: true }) urls: Record<string, string>;
    @Prop() imageAlt: string;

    currentLang = this.language;

    @Watch('currentLang')
    onLangChange() {
        const path = window.location.pathname;
        const localeSegment = `/${this.currentLang}/`;
        window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
    }
}
