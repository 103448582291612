















































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon, CollapseTransition }
})
export default class Combobox extends Vue {
    @Prop() name: string;
    @Prop({ default: () => [] }) items: Record<string, string>[];
    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: '' }) searchLabel: string;
    @Prop({ default: false, type: Boolean }) showCta: boolean;
    @Prop({ default: 'Save' }) ctaLabel: string;
    @Prop({ default: false, type: Boolean }) searchable: boolean;
    @Prop({ default: false, type: Boolean }) pillItems: boolean;
    @Prop() value: string[];

    currentSelection: string[] = [];
    currentSearch = '';
    open = false;

    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
        // respect the initial v-model value
        if (this.value) {
            this.currentSelection = this.value;
        }
    }

    get title(): string {
        return this.currentSelection.length > 0 ? this.items[this.currentSelection[0]] : this.placeholder;
    }

    @Emit('cta')
    emitCta(): void {
        this.open = false;
    }

    shouldItemRender(item: string): boolean {
        if (item === '​') return false;
        if (!this.currentSearch) return true;
        return item.toLowerCase().includes(this.currentSearch.toLowerCase());
    }

    isSelected(item: string): boolean {
        return this.currentSelection.indexOf(item) >= 0;
    }

    toggle(value: string) {
        const index = this.currentSelection.indexOf(value);
        if (index >= 0) {
            // remove from selection
            this.currentSelection.splice(index, 1);
        } else {
            // add to selection
            this.currentSelection.push(value);
        }
    }

    onOutsideClick(evt) {
        let targetElement = evt.target;
        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);
        this.open = false;
    }

    @Watch('currentSelection')
    watchSelection(newVal: string[]): void {
        this.$emit('input', newVal);
    }

    @Watch('value')
    watchValue(): void {
        this.currentSelection = this.value;
    }
}
