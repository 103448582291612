





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import TabHeader from '../base/TabHeader.vue';
import Utils from '../../utils/Utils';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';

@Component({
    components: {
        TabHeader
    }
})
export default class ContentGroup extends Vue {
    @Prop({ default: 'accordion' }) type: string;
    @Prop({ default: false, type: Boolean }) showAll: boolean;
    @Prop({ default: false, type: Boolean }) inContentGroup!: boolean;
    @Prop({ default: true, type: Boolean }) narrow!: boolean;
    @Prop({ default: false, type: Boolean }) scrollableContent!: boolean;
    @Prop({ default: false, type: Boolean }) highlight!: boolean;
    @Prop({ default: null }) defaultIndex!: number;
    @Prop({ default: 0 }) itemsLength!: number;
    @Prop({ default: null }) anchor!: string;
    @Prop() showAllLabel: string;

    currentIndex = this.defaultIndex !== null ? this.defaultIndex : this.isAccordion ? null : 0;
    titles: string[] = [];
    anchors: Array<string|null> = [];

    navStore: NavigationStore = getModule(NavigationStore);

    @Provide()
    get isAccordion(): boolean {
        return this.type === 'accordion';
    }

    @Provide()
    get isNestedAccordion(): boolean {
        return this.isAccordion && this.inContentGroup;
    }

    get activatedTab(): string {
        return this.navStore.activatedTab;
    }

    mounted(): void {
        Vue.nextTick(() => {
            // eslint-disable-next-line
            this.titles = this.$children.filter(c => !!(c as any).title).map(c => (c as any).title);
            this.anchors = this.$children.filter(c => !!(c as any).title)
                .map(c => (c as any).anchor ? (c as any).anchor : Utils.cleanString((c as any).title));
            // show all -> add this option as last tab
            if (this.showAll) {
                this.titles.push(this.showAllLabel);
            }
            this.loadDeepLink();
        });
    }

    loadDeepLink(): void {
        const itemIds: string[] = this.getItemsFromUrl();
        itemIds.forEach(id => this.presetItem(id));
    }

    checkActivatedTab(anchor: string): void {
        if (anchor) {
            const index = this.anchors.findIndex(a => a === anchor);
            if (index >= 0) {
                this.currentIndex = index;
            }
        }
    }

    presetItem(itemId: string, sectionId?: string): void {
        if (!this.anchors.includes(itemId)) return;
        if (!itemId || (sectionId && this.anchor !== sectionId)) {
            this.currentIndex = this.defaultIndex;
            return;
        }
        const itemIndex: number = this.anchors.indexOf(this.anchors.filter(anchor => {
            return anchor === itemId;
        })[0]);
        this.currentIndex = itemIndex >= 0 ? itemIndex : this.defaultIndex;
        this.$children
            .filter(c => !!(c as any).title)
            .forEach(c => {
                (c as any).open = false;
                if ((c as any).anchor === itemId) {
                    /* JS Event Loop Trick: An Asynchronous operation is always executed after all synchronous operationFilters
                     * So this Timeout is executed as soon as Vue has rendered all of its components.
                     */
                    setTimeout(() => (c as any).toggle(), 100);
                }
            });
        const uid = this._uid;
        /* Trigger scroll after page is loaded and synchronous operationFilters have been executed.
         * Let the target element always be the last id found in the url to ensure a deepest child is visible.
         */
        if (this.getItemsFromUrl().indexOf(itemId) === this.getItemsFromUrl().length - 1) {
            window.addEventListener('load', function() {
                /* Subtract 100px from the scroll coordinates to prevent the header or other elements from covering
                 * target content.
                 */
                let el = document.querySelector(`#${itemId}`);

                if (!el) {
                    el = document.querySelector(`#item-${uid}-${itemIndex}`);
                }

                if (el) {
                    const scrollTarget: number = Utils.getPositionElement(el) - 100;
                    setTimeout(() => window.scrollTo({
                        left: 0,
                        top: scrollTarget,
                        behavior: 'smooth'
                    }));
                }
            });
        }
    }

    getItemsFromUrl() {
        return window.location.hash.substring(1).split(':');
    }

    @Watch('activatedTab')
    watchActivatedTab(newVal: string): void {
        if (newVal) {
            this.checkActivatedTab(newVal);
        }
    }
}
