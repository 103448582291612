




































import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from '../base/Icon.vue';

@Component({
    components: { Icon, Dropdown, DropdownItem, CollapseTransition }
})
export default class SearchInputDropdown extends Vue {
    @Prop({ default: () => [] }) entries: Record<string, string>[];
    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: false }) readonly: boolean;
    @Prop({ default: false }) absolute: boolean;
    @Prop({ default: false }) focus: boolean;
    @Prop({ default: 0 }) tabIndex: number;

    @Ref() input: HTMLInputElement;

    keyword = '';
    currentSearch = '';
    open = false;

    get hasResults(): boolean {
        return Object.keys(this.filteredEntries).length > 0;
    }

    get filteredEntries(): Record<string, string> {
        return Object
            .keys(this.entries)
            .filter(key => this.entries[key].toLowerCase().indexOf(this.currentSearch.toLowerCase()) >= 0)
            .reduce((cur, key) => { return Object.assign(cur, { [key]: this.entries[key] }); }, {});
    }

    handleSearch(): void {
        if (this.keyword.length >= 3) {
            this.currentSearch = this.keyword;
            this.open = true;
        } else {
            this.open = false;
        }
    }

    @Emit('selected')
    handleClick(uuid: string): string {
        this.currentSearch = '';
        this.keyword = '';
        this.open = false;
        return uuid;
    }

    @Watch('focus')
    watchFocus(newVal: boolean): void {
        if (newVal) {
            setTimeout(() => this.input.focus(), 500);
        }
    }
}
